import { Link } from "gatsby"
import PropTypes from "prop-types"
import image from "../images/logoJS.jpg"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#0197db`,
      display: `flex`,
      flexDirection: `row`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1000,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link to="#home">
        <img src={image} alt="logo" className="image-header" />
      </Link>

      <font
        className="a-header"
        style={{ margin: 0, textAlign: "center" }}
        size="5"
      >
        <Link
          to="#home"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
        <Link
          to="#destaques"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginLeft: `50px`,
          }}
        >
          Destaques
        </Link>

        <Link
          to="#contact"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginLeft: `50px`,
          }}
        >
          Contato
        </Link>
      </font>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
